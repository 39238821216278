<template>
  <div class="auth-wrapper auth-v2">
    <b-link
      class="brand-logo d-flex justify-content-center align-items-center"
      :to="{ name: 'BookingLogin' }"
    >
      <img :src="this.appLogoImage" width="70" height="70" />
      <h2
        class="brand-text text-primary m-0 ml-1"
        style="font-size: 2rem; font-weight: 600"
      >
        <!--{{ this.appName }}-->
        Guest Room Booking
      </h2>
    </b-link>
    <b-row class="auth-inner m-0 overlay">
      <b-col
        lg="6"
        class=""
        style="display: flex; align-items: center; justify-content: center"
      >
        <div class="text-center position-fixed d-md-block d-none">
          <img
            :src="this.appLogoImage"
            alt="AFP Logo"
            class="img-fluid"
            style="height: 300px; width: 300px"
          />
          <br />
          <br />
          <p
            class="text-primary"
            style="font-size: xx-large; font-weight: bolder"
          >
            Experience Hassle-Free Guest Room Bookings
          </p>
        </div>
      </b-col>

      <b-col lg="6" class="my-auto px-2 p-lg-5">
        <b-col sm="10" md="8" lg="9" class="px-xl-2">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            Welcome to
            <span class="text-primary font-weight-bold">
              <!--{{this.appName}}-->
              Guest Room Booking
            </span>
          </b-card-title>
          <b-card-text class="mb-2" style="color: black">
            Please sign-in to your account
          </b-card-text>

          <validation-observer ref="loginFormValidation">
            <b-form class="auth-login-form mt-2" @submit.prevent>
              <b-form-group>
                <label for="username" style="width: 100%">
                  <div class="d-flex justify-content-between">
                    <div class="d-inline-block">
                      <span>Username</span><span class="text-danger"> *</span>
                    </div>
                    <span
                      class="ml-2 d-inline-block"
                      style="
                        font-family: 'Noto Nastaliq Urdu', serif;
                        text-align: right !important;
                      "
                    >
                      صارف نام درج کریں
                    </span>
                  </div>
                </label>

                <validation-provider
                  #default="{ errors }"
                  name="Username"
                  rules="required"
                >
                  <b-form-input
                    id="username"
                    v-model="username"
                    :state="errors.length > 0 ? false : null"
                    name="Username"
                    placeholder=""
                    style="color: black"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group>
                <label for="password" style="width: 100%">
                  <div class="d-flex justify-content-between">
                    <div class="d-inline-block">
                      <span>Password </span><span class="text-danger"> *</span>
                    </div>
                    <span
                      class="ml-2 d-inline-block"
                      style="
                        font-family: 'Noto Nastaliq Urdu', serif;
                        text-align: right !important;
                      "
                    >
                      پاس ورڈ
                    </span>
                  </div>
                </label>

                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="password"
                      placeholder=""
                      style="color: black"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button
                type="submit"
                variant="primary"
                block
                id="signin"
                @click="validateForm"
              >
                Sign In
              </b-button>
            </b-form>
          </validation-observer>

          <!-- <b-card-text class="text-center mt-2">
                        <span style="color: black">Don't have an account? </span>
                        <b-link :to="{ name: 'NewRegister' }">
                            <span>&nbsp;Click here</span>
                        </b-link>
                    </b-card-text>
                    <b-card-text class="text-center">
                        <span style="color: black">Forgot password? </span>
                        <b-link @click="forgotPasswordBtn">
                            <span>&nbsp;Click here</span>
                        </b-link>
                    </b-card-text> -->
        </b-col>
      </b-col>
    </b-row>
    <OtpModal
      @modalClosed="modalClosed"
      :cnic="username"
      :password="password"
      :key="`create-${OtpModalCount}`"
    />
    <ForgotPasswordModal
      @forgotModalClosed="ForgotPasswordModalClosed"
      :key="`open-${ForgotPasswordModalCount}`"
    />
    <!-- <MaintenancePopupModal
      @MaintenancePopupModalClosed="MaintenancePopupModalClosed"
      :key="`read-${MaintenancePopupModalCount}`"
    /> -->
    <b-overlay :show="show" spinner-variant="primary" no-wrap />
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import OtpModal from "@/components/auth/OtpModal.vue";
import ForgotPasswordModal from "@/components/auth/ForgotPasswordModal.vue";
// import MaintenancePopupModal from '@/components/booking/admin/MaintenancePopupModal.vue';
import { $themeConfig } from "@themeConfig";
import { mapActions, mapGetters } from "vuex";
import util from "@/util.js";
import store from "@/store";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    OtpModal,
    ForgotPasswordModal,
    // MaintenancePopupModal
  },
  mixins: [togglePasswordVisibility, util],
  data() {
    return {
      appLogoImage: require("@/assets/images/logo/bookinglogo.png"),

      show: false,
      appName: "",
      password: "",
      OtpModalCount: 0,
      ForgotPasswordModalCount: 0,
      // MaintenancePopupModalCount:0,
      username: "",
      required,
    //   bookingRoles: [
    //   'bk__agm',
    //   'bk__mess_sec',
    //   'bk__fmn_adm'
    // ]
    };
  },
  created() {
    this.appName = $themeConfig.app.appName;
    //this.appLogoImage = $themeConfig.app.appLogoImage;
  },
  mounted()
  { 
    // this.maintainanceModal();
  },
  methods: {
    ...mapActions({
      login: "appData/login",
      sendOtp: "appData/sendOtp",
      logout: "appData/logoutUnAuth",
    }),
    forgotPasswordBtn() {
      this.ForgotPasswordModalCount += 1;
      this.$nextTick(() => {
        this.$bvModal.show("forgot-password-modal");
      });
    },
    // maintainanceModal() {
    //   this.MaintenancePopupModalCount += 1;
    //   this.$nextTick(() => {
    //     this.$bvModal.show("maintenance-popup-modal");
    //   });
    // },
    // async MaintenancePopupModalClosed() {
    //   this.$bvModal.hide("maintenance-popup-modal");
    // },
    async ForgotPasswordModalClosed() {
      this.$bvModal.hide("forgot-password-modal");
    },
    async modalClosed() {
      this.$bvModal.hide("otp-modal");
    },
    openModal() {
      this.OtpModalCount += 1;
      this.$nextTick(() => {
        this.$bvModal.show("otp-modal");
      });
    },
    async validateForm() {
      const success = await this.$refs.loginFormValidation.validate();
      if (success) {
        await this.submit();
      }
    },
    async submit() {
      this.show = true;
      try {
        if(!isNaN(this.username)){
          this.displayCustomError("Invalid Login");
          this.show = false;
          return
        }
        const res = await this.login({
          cnic: this.username,
          password: this.password,
        });
        if (res.status === 200) {
          // if(this.getUser.role_data.code_name=='bk__mess_sec'){
          //   store.commit("appConfig/UPDATE_SKIN", "light");
          //   this.$router.push({ name: "Booking" });            
          // }
          if (
            (this.getUser.user_type == this.userTypes.SERVING ||
              this.getUser.user_type == this.userTypes.RETIRED ||
              this.getUser.user_type == this.userTypes.CIVILIAN) &&
              // this.bookingRoles.includes(this.getUser.role_data.code_name)
              this.getUser.role_data.code_name.includes('bk__')
          ) {
            store.commit("appConfig/UPDATE_SKIN", "light");
            await this.logout();
            this.openModal();
          } else {
            await this.logout();
            this.displayCustomError("Invalid Login");
          }
        }
        this.show = false;
      } catch (error) {
        this.show = false;
        this.displayError(error);
      }
    },
  },
  computed: {
    ...mapGetters({
      getUser: "appData/getUser",
      hasPermisson: "appData/hasPermission",
      hasRole: "appData/hasRole",
    }),
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>

<style scoped>
.overlay {
  background: url("~@/assets/images/slider/booking_bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  position: absolute;
  background-blend-mode: multiply;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}

.urdu-text {
  direction: ltr;
  font-family: "Noto Nastaliq Urdu", serif;
}

label {
  font-size: 110%;
  font-weight: 300;
  color: #000;
}
::placeholder {
  color: black;
}
</style>
